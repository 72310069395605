$base-color: rgba(218, 88, 12, 0.678);
$old-prime: rgba(28, 218, 161, 0.527);
$secondary: #eff;
$theme-colors: (
  "info": rgba(84, 156, 24, 0.753),
  "primary": $base-color,
  "light": #eff,
  "danger": tomato,
);

$breakpoint-mobile-landscape: 640px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 1280px;

$card-bg: #eff;
$container-bg: #eff;
$spinner-border-width: 0.5rem;
$spinner-width: 2rem;
$spinner-height: $spinner-width;
$spinner-animation-speed: 0.5s;
$carousel-control-width: 5%;
$carousel-control-icon-height: 6em;
$carousel-control-icon-width: 6em;
$carousel-control-color: $base-color;
// $carousel-control-color: $base-color;
// $carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
// $carousel-control-next-icon-bg: url("data:image/svg+xml,<svg width='400' height='180'><rect x='50' y='20' rx='20' ry='20' width='150' height='150' style='fill:red;stroke:black;stroke-width:5;opacity:0.5' /></svg>") !default;

@import "../../node_modules/bootstrap/scss/bootstrap";
/* this has to be at the bottom */
