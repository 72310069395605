@import "../../../node_modules/bootstrap/scss/bootstrap";
// @import "@Sass/custom";
@import "@../../../../Sass/custom.scss";

.interactionCard {
  vertical-align: text-bottom;
  // font-weight: bolder;
  margin: 10px 0px;
  color: black;
  text-shadow: 0px 2px 5px 2px rgb(109, 99, 99);
  padding: 2px 5px 5px 5px;
  box-shadow: 0px 2px 5px 2px rgb(109, 99, 99);
  overflow-y: scroll;
}
.medName {
  color: red;
}

.accordianHeader {
  // font-weight: lighter;
  margin-top: 4px;
  cursor: pointer;
  background-color: white;
  color: black;
  padding: 3px;
  vertical-align: text-bottom;
  // // margin: 5px;
  // box-shadow: -1px 1px 2px 1px rgb(109, 99, 99);
}
.accordianCollapse {
  padding: 0px 0px;
  color: black;
  // font-weight: lighter;
  font-size: smaller;
  // margin: 5px;
  background-color: white;
  box-shadow: inset 1px 1px 4px 1px rgb(168, 156, 156);
}

.innerInteractionCard {
  padding: 10px 8px;
}
.interactionRow {
  background-color: $secondary;
  margin-top: 6px;
}
.interactionReferences {
  text-align: center;
  background-color: $secondary;
}
