@import "../../../node_modules/bootstrap/scss/bootstrap";
// @import "@Sass/custom";
@import "@../../../../Sass/custom.scss";

datalist {
  position: absolute;
  color: black;
  background-color: lightgrey;
  font-size: 0.8rem;
  max-height: 150px;
  overflow-y: scroll;
}
.spinner {
  @extend .spinner-border;
}
option {
  background-color: white;
  color: black;
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  padding-left: 10px;
  margin-bottom: 1px;
  cursor: pointer;
}
datalist:hover > option:hover {
  background-color: $secondary;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  cursor: pointer;
}
datalist > option:focus {
  background-color: $secondary;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  cursor: pointer;
  padding-left: 10px;
}
.medTable {
  @extend .table-striped, .table-hover;
  overflow-y: scroll;
  font-size: small;
}
@media screen and (min-width: 768px) {
  .medTable {
    max-height: 400px;
    font-size: 14px;
  }
}
