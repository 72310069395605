@import "../../../node_modules/bootstrap/scss/bootstrap";
// @import "@Sass/custom";
@import "@../../../../Sass/custom.scss";

.modalHeader {
  background-color: $base-color;
  color: white;
  box-shadow: $box-shadow;
}
.modalFooter {
  background-color: $secondary;
}
.modal90w {
  min-width: 100vw;
  font-size: 0.8rem;
  max-height: 100vh;
  min-height: 100vh;
  // max-width: none !important;
  margin: 0px;
}

.modalBody {
  overflow-y: scroll;
  padding: 10px 10px;
  // min-height: 400px;
  box-shadow: $box-shadow;
}
.carouselItem {
  padding: 2em 1em 2em 1em;
}
blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}
.internalHeader {
  @extend .row;
}
@media only screen and (min-width: $breakpoint-tablet) {
  .modal90w {
    min-width: 80%;
    position: absolute;
    left: 10%;
    top: 5%;
    overflow-y: unset;

    min-height: 600px;
    height: 70%;

    font-size: 1.05em;
  }
}
