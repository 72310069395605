@import "../../../node_modules/bootstrap/scss/bootstrap";
// @import "@Sass/custom";
@import "@../../../../Sass/custom.scss";

.textRed {
    color: crimson;
}
.imageHolder{
    @extend .flex-grow-1;
    background:white;
    
    background-position: center;
    background-repeat: no-repeat;

}