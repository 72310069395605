@import "../../../node_modules/bootstrap/scss/bootstrap";
// @import "@Sass/custom";
@import "@../../../../Sass/custom.scss";

.patientList {
  @extend .table-hover, .table-striped, .card;
  height: inherit;
  position: relative;
  overflow-y: scroll !important;
  display: none;
  font-size: 12px;
  padding: 0.1vw;
  margin: 0.1vw;
}

.patientListContainer {
  height: inherit;
  position: relative;
}
.fixHeader {
  position: fixed;
}
.medButton {
  @extend .btn-sm, .btn-info;
  cursor: pointer;
  max-width: 100%;
}
.medButtonRow td {
  padding: 0.1vw;
  margin: 0.1vw;
}
.medButtonRow > .medButton {
  visibility: hidden;
}
.medButtonRow {
  font-size: 11px;
}
@media screen and (min-width: 768px) {
  .medButtonRow {
    padding: 0.4em;
    margin: 0.4em;

    font-size: 16px;
  }
  .medButtonRow td {
    padding: 0.5vw;
    margin: 0.5vw;
  }
}
.medButtonCell :hover > .medButton {
  visibility: visible;
}
.headers {
  padding-left: 0px;
  font-size: 16px;
}
#patientList {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
  display: none;
}
