@import "../../../node_modules/bootstrap/scss/bootstrap";
// @import "@Sass/custom";
@import "@../../../../Sass/custom.scss";

.loginContainer {
  @extend .container;
  margin: 20vh auto;
  display: block;
}

.textRed {
  color: crimson;
}
.imageHolder {
  @extend .flex-grow-1;
  background: white;

  background-position: center;
  background-repeat: no-repeat;
}

@media only screen and (min-width: $breakpoint-tablet) {
  .topLogo {
    display: none;
  }
}
