@import "../../node_modules/bootstrap/scss/bootstrap";
// @import "@Sass/custom";
@import "@../../../Sass/custom.scss";

#authLayout {
  display: none;
}
.authLayout {
  display: none;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
}
@-webkit-keyframes animatebottom {
  from {
    padding-top: 100px;
    opacity: 0;
  }
  to {
    padding-top: 0px;
    opacity: 1;
  }
}

@keyframes animatebottom {
  from {
    padding-top: 100px;
    opacity: 0;
  }
  to {
    padding-top: 0px;
    opacity: 1;
  }
}
.loadingBorder {
  // width: 100%;
  height: 8px;
  background-color: transparent;
  top: 50px;
  border-end-end-radius: 10%;
  position: absolute;
  animation: loadingGradient 1s linear infinite;
}
@keyframes loadingGradient {
  0% {
    left: 50%;
    width: 0%;
    background-color: whitesmoke;
  }
  50% {
    left: 25%;
    width: 50%;
    background-color: rgb(205, 103, 90);
  }
  100% {
    left: 0%;
    width: 100%;
    background-color: whitesmoke;
  }
}
// @keyframes loadingGradient {
//   0% {
//     background-image: linear-gradient(
//       to right,
//       whitesmoke,
//       blue,
//       whitesmoke,
//       transparent,
//       transparent,
//       transparent,
//       transparent,
//       transparent,
//       transparent,
//       transparent
//     );
//   }
//   10% {
//     background-image: linear-gradient(
//       to right,
//       transparent,
//       whitesmoke,
//       blue,
//       whitesmoke,
//       transparent,
//       transparent,
//       transparent,
//       transparent,
//       transparent,
//       transparent
//     );
//   }
//   20% {
//     background-image: linear-gradient(
//       transparent,
//       transparent,
//       to right,
//       whitesmoke,
//       blue,
//       whitesmoke,
//       transparent,
//       transparent,
//       transparent,
//       transparent,
//       transparent
//     );
//   }
//   30% {
//     background-image: linear-gradient(
//       to right,
//       transparent,
//       transparent,
//       transparent,
//       whitesmoke,
//       blue,
//       whitesmoke,
//       transparent,
//       transparent,
//       transparent,
//       transparent
//     );
//   }
//   40% {
//     background-image: linear-gradient(
//       to right,
//       transparent,
//       transparent,
//       transparent,
//       transparent,
//       whitesmoke,
//       blue,
//       whitesmoke,
//       transparent,
//       transparent,
//       transparent
//     );
//   }
//   50% {
//     background-image: linear-gradient(
//       to right,
//       transparent,
//       transparent,
//       transparent,
//       transparent,
//       transparent,
//       whitesmoke,
//       blue,
//       whitesmoke,
//       transparent,
//       transparent
//     );
//   }
//   60% {
//     background-image: linear-gradient(
//       to right,
//       transparent,
//       transparent,
//       transparent,
//       transparent,
//       transparent,
//       transparent,
//       whitesmoke,
//       blue,
//       whitesmoke,
//       transparent
//     );
//   }
//   60% {
//     background-image: linear-gradient(
//       to right,
//       transparent,
//       transparent,
//       transparent,
//       transparent
//       transparent,
//       transparent,
//       transparent,
//       whitesmoke,
//       blue,
//       whitesmoke,
//     );
//   }
//   70% {
//     background-image: linear-gradient(
//       to right,
//       whitesmoke,
//       transparent,
//       transparent,
//       transparent,
//       transparent
//       transparent,
//       transparent,
//       transparent,
//       whitesmoke,
//       blue,
//     );
//   }
//   80% {
//     background-image: linear-gradient(
//       to right,
//       blue,
//       whitesmoke,
//       transparent,
//       transparent,
//       transparent,
//       transparent
//       transparent,
//       transparent,
//       transparent,
//       whitesmoke,
//     );
//   }
//   90% {
//     background-image: linear-gradient(
//       to right,
//       whitesmoke,
//       blue,
//       whitesmoke,
//       transparent,
//       transparent,
//       transparent,
//       transparent
//       transparent,
//       transparent,
//       transparent,
//     );
//   }
//   100% {
//     background-image: linear-gradient(
//       to right,
//       whitesmoke,
//       blue,
//       whitesmoke,
//       transparent,
//       transparent,
//       transparent,
//       transparent,
//       transparent,
//       transparent
//       transparent,
//       transparent,
//       transparent,
//     );
//   }
// }
.sansTop {
  @extend .col-12, .col-sm-3, .p-0, .flex-shrink-1;
  // display: none;
  // max-width: 200px;
  position: relative;
}
.topbar {
  visibility: hidden;
  display: none;

  // grid: 50px / max-content auto max-content;
  /* Stay on top */
  top: 0;
  width: 100vw;
  /* Stay at the top */
  // left: 200;
  position: fixed;
  // padding: 5px 10px;
  // padding-left: 200px;
  // padding-right: 10px;
  /* Same as the width of the sidebar */
  z-index: 2;
  padding: 1px 2px;
  height: 42px;
  background-color: $secondary;
  box-shadow: 0px 1px 2px 0px rgb(109, 99, 99);
}
.header {
  display: none;
}

.sidenav {
  @extend.navbar , .navbar-expand-md, .navbar-dark, .bg-primary, .align-items-start, .flex-md-column, .flex-row;
  box-shadow: 0px 2px 4px 2px rgb(109, 99, 99);
  // height: 100%;
  /* Full-height: remove this if you want "auto" height */
  width: 100vw;
  /* Set the width of the sidebar */
  position: fixed;
  /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1;
  /* Stay on top */
  // top: 50;
  /* Stay at the top */
  left: 0;
  // background-color: $secondary;
  // max-height: 50px;
  overflow-x: hidden;
  /* Disable horizontal scroll */
  // padding-top: 60px;
  font-weight: bold;
}
.patientInfo {
  visibility: hidden;
}
.patientInfoNav {
  text-align: center;
  visibility: visible;
  width: max-content;
  // font-weight: bold;
  font-size: 16px;
  // display: inline-flex;
  color: rgb(231, 230, 230);
}
.spacer {
  visibility: hidden;
  display: none;
  grid-column: 2 /3;
  box-shadow: inset 0px 1px 2px 1px rgb(163, 161, 161);
}
.activeUserBanner {
  // grid-column: 3 / 4;

  display: inline-flex;
  // grid: 50px / 50px auto;
  // grid-column-gap: 4px;
}
.profileThumb {
  visibility: hidden;
  position: fixed;
  right: 1px;
  // grid-column: 1 / 2;
  // grid-row: 1 / 3;
  height: 40px;
  width: 40px;
  display: inline-flex;
  border-radius: 50%;
}
.profileThumbNav {
  visibility: visible;
  height: 40px;
  width: 40px;
  position: fixed;
  left: 5px;
  top: 5px;
  border-radius: 50%;
  border-width: 1px;
  border-color: white;
  border-style: solid;
  box-shadow: 0px 0px 3px 1px rgb(163, 161, 161);
}
.brand {
  display: none;
}
.nameCompenent {
  display: inline-flex;
  visibility: hidden;
}
.userName {
  visibility: hidden;

  font-weight: bold;
}
.logoutButton {
  display: inline-flex;
  color: darkblue;
  font-size: small;
}
.practitionerId {
  visibility: hidden;
}
.sidenav div a {
  text-align: right;
  transition: ease-in-out;
  transition-duration: 200ms;
  // padding: 4px 10px 4px 5px;
  color: white !important;
  text-shadow: 1px 1px rgba(104, 38, 7, 0.911);
  font-size: 16px;
  font-weight: 600;
  // margin: 20px 1px 20px 10px;
  // display: block;
  // width: 170px;
  padding-right: 5px;
  text-decoration: none;
  border-left: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  // border-color: transparent;
  // border-top-left-radius: 12px;
  // border-bottom-left-radius: 12px;
}

/* When you mouse over the navigation links, change their color */

.sidenav div a:hover {
  background-color: $base-color;
  color: white;
  // width: 185px;

  // margin-left: 15px;
  text-decoration: none;

  // padding: 4px 5px 4px 10px;
  transition: ease-out;
  transition-duration: 300ms;

  // border-top-left-radius: 12px;
  // border-bottom-left-radius: 12px;
  box-shadow: -2px 3px 6px 2px rgba(0, 0, 0, 0.349);
}
.sidenav div a:active {
  background-color: white;
  color: $base-color;
  // width: 185px;

  // margin-left: 15px;
  // padding: 4px 10px 4px 20px;

  transition: ease-out;
  transition-duration: 150ms;
}
@media only screen and (min-width: $breakpoint-tablet) {
  .bannerImage {
    height: 65%;
    width: 65%;
    margin: 10px 0;
  }
  #topNav {
    visibility: hidden;
  }

  .profileThumbNav {
    visibility: hidden;
  }
  .patientInfoNav {
    visibility: hidden;
  }
  .brand {
    display: none;
  }
  .sansTop {
    max-width: 200px;
    max-height: 100vh;
    overflow-y: hidden;
  }
  .sidenav {
    max-width: 200px;
    box-shadow: inset -1px 0px 6px 2px rgb(109, 99, 99);
    max-height: 100vh;
    height: 100%;
    /* Full-height: remove this if you want "auto" height */
    // width: 200px;

    /* Set the width of the sidebar */
    position: fixed;
    /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1;
    display: flow-root;
    justify-content: start;
    /* Stay on top *
    // top: 50;
    /* Stay at the top */
    left: 0;
    background-color: $base-color;

    overflow-x: hidden;
    /* Disable horizontal scroll */
    padding-top: 60px;
    font-weight: bold;
  }
  .topbar {
    visibility: visible;
    display: grid;

    grid: 50px / max-content auto max-content;
    /* Stay on top */
    top: 0;
    width: 100vw;
    /* Stay at the top */
    // left: 200;
    position: fixed;
    // padding: 5px 10px;
    // padding-left: 200px;
    // padding-right: 10px;
    /* Same as the width of the sidebar */
    z-index: 1;
    height: 50px;
    background-color: $secondary;
    box-shadow: 0px 1px 5px 2px rgb(109, 99, 99);
  }
  .header {
    display: block;
    visibility: visible;
    padding: 6px 0px 0px 10px;
    position: fixed;
    text-align: center;
    border: 1px solid grey;
    /* Fixed Sidebar (stay in place on scroll) */
    z-index: 2;
    /* Stay on top */
    top: 0;
    /* Stay at the top */
    left: 0;
    height: 60px;
    background-color: $secondary;
    width: 210px;
    box-shadow: -2px 0px 8px 2px rgb(109, 99, 99);
  }
  .patientInfo {
    visibility: visible;
    padding-left: 220px;
    padding-right: 60px;
    font-weight: bold;
    grid-column: 1 /2;
  }
  .spacer {
    visibility: visible;
    grid-column: 2 /3;
    box-shadow: inset 0px 1px 2px 1px rgb(163, 161, 161);
  }
  .activeUserBanner {
    visibility: visible;
    grid-column: 3 / 4;
    padding-right: 5px;
    display: grid;
    grid: 50px / 50px auto;
    grid-column-gap: 4px;
  }
  .profileThumb {
    visibility: visible;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    position: relative;
    height: 50px;
    width: 50px;
    border-radius: 0%;
  }
  .nameCompenent {
    visibility: visible;
    grid-column: 2 /3;
    grid-row: 1 / 3;
    display: grid;
    grid: min-content min-content / max-content auto;
    grid-row-gap: 5px;
  }
  .userName {
    visibility: visible;
    grid-column: 1 / 2;
    grid-row: 1/2;
    font-weight: bold;
  }
  .logoutButton {
    color: darkblue;
    font-size: small;
    grid-column: 2 /3;
    grid-row: 1/2;
  }
  .practitionerId {
    visibility: visible;
    grid-column: 1 /3;
    grid-row: 2 / 3;
  }

  .sidenav div a {
    transition: ease-in-out;
    transition-duration: 200ms;
    // padding: 4px 10px 4px 5px;
    color: white;
    text-shadow: 1px 1px rgba(104, 38, 7, 0.911);
    font-size: 20px;
    font-weight: 700;
    // margin: 20px 1px 20px 10px;
    display: block;
    // width: 170px;
    text-decoration: none;
    border-left: 1px solid transparent;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    // border-color: transparent;
    // border-top-left-radius: 12px;
    // border-bottom-left-radius: 12px;
  }

  .sidenav div a:hover {
    // background-color: $base-color;
    color: white;
    // width: 185px;

    // margin-left: 15px;
    text-decoration: none;

    // padding: 4px 5px 4px 10px;
    transition: ease-out;
    transition-duration: 300ms;

    // border-top-left-radius: 12px;
    // border-bottom-left-radius: 12px;
    box-shadow: -2px 3px 6px 2px rgba(0, 0, 0, 0.349);
  }
  .sidenav div a:active {
    background-color: white;
    color: $base-color;
    // width: 185px;

    // margin-left: 15px;
    // padding: 4px 10px 4px 20px;

    transition: ease-out;
    transition-duration: 150ms;
  }
}
.main {
  @extend .col, .container-fluid;
  // margin-top: 20px;
  // width: 100%;
  padding: 60px 0px 0px 0px;
  // position: fixed;
  // width: calc((100%) - 10px);
  // height: calc((100%) - 80px);
  // display: flex;
  // overflow-y: scroll;
  // border: 1px solid rgba(0, 0, 0, 0.377);
  //box-shadow: 0px -13px 6px 2px rgba(0, 0, 0, 0.349);
  /* Same as the width of the sidebar */

  // z-index: 2;
}
/* Style page content */

/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */

// @media screen and (max-height: 450px) {
//   .sidenav {
//     padding-top: 15px;
//   }
//   .sidenav div {
//     font-size: 18px;
//   }
// }

// @media screen and (max-width: 450px) {
//   .sidenav {
//     width: 100%;
//     height: auto;
//     position: relative;
//   }
//   .sidenav div {
//     float: left;
//   }
//   div.content {
//     margin-left: 0;
//   }
// }

// @media screen and (max-width: 400px) {
//   .sidenav div {
//     text-align: center;
//     float: none;
//   }
// }
