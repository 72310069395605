@import "../../../node_modules/bootstrap/scss/bootstrap";
// @import "@Sass/custom";
@import "@../../../../Sass/custom.scss";

.patientListCard {
  @extend .card-body;
  width: 100%;
}
@media screen and (min-width:  568px) {
  .patientListCard {
    // height: inherit;
    // position: fixed;
    // width: calc((100%) - 180px);
  }
}

