@import "../../node_modules/bootstrap/scss/bootstrap";
@import "custom";

.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #fff;
}
.btn-google {
  color: #fff;
  background-color: #ea4335;
  border-color: #fff;
}
// .loader {
//   position: absolute;
//   left: 50%;
//   right: 50%;
//   top: 50%;
//   border: 16px solid #f3f3f3;
//   border-radius: 50%;
//   border-top: 16px solid $base-color;
//   border-right: 16px solid $secondary;
//   border-bottom: 16px solid red;
//   border-left: 16px solid transparent;
//   width: 120px;
//   height: 120px;
//   margin: -76px 0 0 -76px;
//   -webkit-animation: spin 1.25s linear infinite;
//   animation: spin 1.25s linear infinite;
// }
#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid $base-color;
  border-right: 16px solid $secondary;
  // border-bottom: 16px solid red;
  border-left: 16px solid transparent;

  margin: -76px 0 0 -76px;
  -webkit-animation: spin 1.25s linear infinite;
  animation: spin 1.25s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Add animation to "page content" */
.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
}

@-webkit-keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0px;
    opacity: 1;
  }
}

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

#fromBottom {
  display: none;
  text-align: center;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
